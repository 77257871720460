<template>
  <div
    class="form-item grid"
    :class="{ required: required, disabled: disabled, error: isError, active: isOpen }"
    :data-success="isSuccess"
  >
    <label v-if="label" class="form-item__label">
      {{ label }}
    </label>

    <div class="flex ai-c ggap-10">
      <PickColors class="form-color-picker" v-model:value="propValue" :size="30" />
      {{ propValue }}
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, toRefs, ref, computed } from 'vue'
import PickColors from 'vue-pick-colors'

// Emits
const emits = defineEmits(['update:modelValue'])

// Props
const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false
  },
  isError: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: String,
    default: ''
  }
})

// Data
const { label, required, modelValue } = toRefs(props)
const isOpen = ref(true)

// Computed
const propValue = computed({
  get: () => modelValue.value,
  set: (val) => emits('update:modelValue', val)
})
const isSuccess = computed(() => {
  return !!propValue.value
})
</script>

<style lang="scss">
.form-color-picker {
  .colors {
    display: flex;
    align-items: flex-start;
    grid-gap: 10px;
    flex-wrap: wrap;
  }
  .color-item {
    margin: 0 !important;
  }
}
</style>
